<template>
  <b-img :src="require('@/assets/images/logo/logo.png')" />
</template>
<script>
import {
  BImg,
} from 'bootstrap-vue'

export default {
  components: {
    BImg,
  },
}
</script>
